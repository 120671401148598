.CustomMetricWidgetPreview_wrapper_1nskvDN {
  background-color: #ddd;
  /* border: solid thin $gray-medium; */
  border-radius: 3px;
  padding: 20px;
}

.CustomMetricWidgetPreview_innerWapper_1qIO0En {
  border-radius: 3px;
  width: 70%;
  margin: 0 auto;
  background-color: white;
  min-height: 220px;
}